/** Validaciones personalizadas */
import {
    extend
} from 'vee-validate';
import {
    required,
    email,
    excluded,
    required_if,
    min,
    confirmed
} from 'vee-validate/dist/rules';
extend('email', email);
extend('required', required);
extend('excluded', excluded);
extend('required_if', required_if);
extend('min', min);
extend('confirmed', confirmed);
//daocumento fiscal
extend("validFiscalID", {
    validate: (value) => {
        const regExpFiscalID = new RegExp("^([VEJPG]{1})-([0-9]{8})-([0-9]{1}$)");
        return regExpFiscalID.test(value);
    },
    message: 'El formato ingresado no es válido'
});
//instagram
extend("validInstagram", {
    validate: (value) => {
        const regInstagramName = new RegExp("^[a-zA-Z0-9_.]{1,30}$");
        return regInstagramName.test(value);
    },
    message: 'El formato ingresado no es válido'
});
//twitter
extend("validTwitter", {
    validate: (value) => {
        const regTwitterName = new RegExp("^[a-zA-Z0-9_]{1,15}$");
        return regTwitterName.test(value);
    },
    message: 'El formato ingresado no es válido'
});
//youtube
extend("validYoutube", {
    validate: (value) => {
        const regYoutubeChannel = new RegExp("((http|https):\/\/|)(www\.|)youtube\.com\/(channel\/|user\/)[a-zA-Z0-9\-]{1,}");
        return regYoutubeChannel.test(value);
    },
    message: 'El formato ingresado no es válido'
});
//linkedin
extend("validLinkedin", {
    validate: (value) => {
        const regLinkedinCompany = new RegExp("((http|https):\/\/|)(www.|)linkedin\.com\/(company\/|user\/|)([a-zA-Z0-9_-]{1,}$)");
        return regLinkedinCompany.test(value);
    },
    message: 'El formato ingresado no es válido'
});
//facebook
extend("validFacebook", {
    validate: (value) => {
        //const regFacebookUser = new RegExp(/^(www\.)?(facebook|fb)\.com\/[A-z0-9_\-\.]+\/?/);
        const regFacebookUser = new RegExp(/^(www\.)?(facebook|fb)\.com\/[A-z0-9_\-\.]+\/?/);
        return regFacebookUser.test(value);
    },
    message: 'El formato ingresado no es válido'
});
//pagina web
extend("validWeb", {
    validate: (value) => {
        const regUrlWeb = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/);
        return regUrlWeb.test(value);
    },
    message: 'El formato ingresado no es válido'
});
//formato de los emails en un textarea
extend("validMultiEmails", {
    validate: (value) => {
        let emailList = value.split(",").map(function (item) {
            return item.trim();
        })
        let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
        for (let i = 0; i < emailList.length; i++) {
            let validEmail = regex.test(emailList[i])
            if (!validEmail) {
                return false
            }
        }
        return true
    },
    message: 'El formato ingresado no es válido'
});
//cantidad permitida de emails en un textarea
extend("cantEmailsTextArea", {
    validate: (value) => {
        let emailList = value.split(",").map(function (item) {
            return item.trim();
        })
        if (emailList.length > 5) {
            return false
        }
        return true
    },
    message: 'Solo puede ingresar hasta 5 E-Mails'
});
//formato de los whatsapp en un textarea
extend("validMultiWhatsApp", {
    validate: (value) => {
        let whatsAppList = value.split(",").map(function (item) {
            return item.trim();
        })
        //let regex = /^(\(04+(12|14|16|24|26)\)).([0-9]{3})-([0-9]{4}$)/
        let regex = /^((\([0-9]{4}\)).([0-9]{3})-([0-9]{4}))|(^\+?[\d]+$)/
        for (let i = 0; i < whatsAppList.length; i++) {
            let validWhatsApp = regex.test(whatsAppList[i])
            if (!validWhatsApp) {
                return false
            }
        }
        return true
    },
    message: 'El formato ingresado no es válido'
});
//cantidad permitida de WhatsApp en un textarea
extend("cantWhatsAppTextArea", {
    validate: (value) => {
        let whatsAppList = value.split(",").map(function (item) {
            return item.trim();
        })
        if (whatsAppList.length > 3) {
            return false
        }
        return true
    },
    message: 'Solo puede ingresar hasta 3 números de WhatsApp'
});
//validador de telefonos, celulares y/o locales
extend("validPhone", {
    validate: (value) => {
        const regPhone = new RegExp(/^(\(0+([0-9]{3})\))([0-9]{3}).([0-9]{4}$)/);
        return regPhone.test(value);
    },
    message: 'El formato ingresado no es válido'
});
//formato para los telefonos en el textarea, con o sin extensiones
extend("validMultiPhones", {
    validate: (value) => {
        let phonesList = value.split(",").map(function (item) {
            return item.trim();
        })
        let regex = /^(\(0+[0-9]{3}\)[0-9]{3}\.[0-9]{4}$)|^(\(0+[0-9]{3}\)[0-9]{3}\.[0-9]{4})+((\ Ext\.+[0-9]{2,10}){1,4}$)/
        for (let i = 0; i < phonesList.length; i++) {
            let validMultiPhones = regex.test(phonesList[i])
            if (!validMultiPhones) {
                return false
            }
        }
        return true
    },
    message: 'El formato ingresado no es válido'
});
extend('distinct', {
    params: ['target'],
    validate(value, {
        target
    }) {
        return value.includes(target)?false:true
    },
    message: 'El E-Mail adicional debe ser diferente al principal'
});