<template>
	<div class="limiter">
		<loading-screen
			v-if="isLoading"
			information-text="Iniciando Sesi&oacute;n"
		></loading-screen>
		<div class="container-login100 imgBack">
			<div class="wrap-login100">
				<CAlert v-if="this.invalidForm" color="danger">
					<span>{{ this.message }}</span>
					<br />
				</CAlert>
				<div class="login100-pic">
					<img :src="imgLogo" alt="IMG" />
				</div>
				<ValidationObserver v-slot="{ valid, errors }">
					<div>
						<input
							type="hidden"
							id="valid"
							ref="valid"
							class="hidden"
							:value="valid"
						/>
						<input
							type="hidden"
							id="error"
							ref="error"
							class="hidden"
							:value="errors[0]"
						/>
						<form
							class="login100-form validate-form"
						>
							<div style="display: none;" class="imgSecondary">
								<img :src="imgLogo" alt="IMG" />
							</div>
							<span class="login100-form-title textLogin">
								Iniciar Sesión
							</span>
							<div class="wrap-input100">
								<ValidationProvider
									name="E-Mail"
									rules="required"
									v-slot="{ valid, errors }"
								>
									<input
										v-model="user.username"
										class="input100"
										type="text"
										name="infoemail"
										placeholder="E-mail"
										autocomplete="off"
									/>
									<span class="focus-input100"></span>
									<span class="symbol-input100">
										<i
											class="fas fa-envelope"
											aria-hidden="true"
										></i>
									</span>
									<span
										v-if="!valid"
										style="color:black; font-weight: bold;"
										>{{ errors[0] }}</span
									>
								</ValidationProvider>
							</div>
							<div class="wrap-input100">
								<ValidationProvider
									name="Contraseña"
									rules="required"
									v-slot="{ valid, errors }"
								>
									<input
										v-model="user.password"
										class="input100"
										type="password"
										name="infopassword"
										placeholder="Contraseña"
										autocomplete="off"
									/>
									<span class="focus-input100"></span>
									<span class="symbol-input100">
										<i
											class="fas fa-lock"
											aria-hidden="true"
										></i>
									</span>
									<span
										v-if="!valid"
										style="color:black; font-weight: bold;"
										>{{ errors[0] }}</span
									>
								</ValidationProvider>
							</div>
							<div class="container-login100-form-btn">
								<b-button
									@click="handleLogin"
									pill
									class="login100-form-btn"
									variant="success"
									>Ingresar
								</b-button>
							</div>
						</form>
					</div>
				</ValidationObserver>
			</div>
		</div>
	</div>
</template>

<script>
	import '../validations/validationsForm.js';
	import User from '../models/user';
	import { httpCodes } from '../constants/httpCodes';
	export default {
		/** Componente para el inicio de sesión en el sistema */
		name: 'Login',
		title: 'Iniciar Sesión | Infoguia',
		data() {
			return {
				userName: '',
				password: '',
				imgLogo: process.env.VUE_APP_LOGO_INFOGUIA,
				error: '',
				invalidForm: false,
				user: new User('', ''),
				message: '',
				isLoading: false,
			};
		},
		beforeCreate: function() {},
		computed: {
			loggedIn() {
				if (sessionStorage.getItem('logged')) {
					return true;
				}
				return false;
			},
		},
		created() {
			if (this.loggedIn) {
				this.$router.push({
					name: 'Home',
				});
			}
		},
		mounted() {},
		methods: {
			fakeLogin() {
				this.$router.push({
					name: 'Home',
				});
			},
			handleLogin() {
				let valid = this.$refs.valid.value;
				this.isLoading = true;
				if (valid === 'true') {
					if (this.user.username && this.user.password) {
						this.$store
							.dispatch('auth/login', this.user)
							.then((response) => {
								if (response === process.env.VUE_APP_EMPLOYEE) {
									this.getUserData();
								} else {
									this.$router.push({
										name: 'DeleteData',
									});
								}
							})
							.catch((error) => {
								this.invalidForm = true;
								console.log(error);
								if (
									error.response.data.code ===
									httpCodes.notAuthorized
								) {
									this.message = error.response.data.error;
								}
							})
							.finally(() => {
								this.isLoading = false;
							});
					}
				}
			},
			getUserData() {
				this.$store
					.dispatch('auth/getDataUser')
					.then((dataUser) => {
						this.$router.push({
							name: 'Home',
						});
					})
					.catch((error) => {
						console.log(error.response);
					})
					.finally(() => {
						this.isLoading = false;
					});
			},
		},
		watch: {},
	};
</script>
<style>
	.imgLoading {
		margin-top: 10% !important;
	}
</style>
